import * as React from "react";
import { useNavigate, Outlet, Link } from "react-router-dom";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  homeIcon,
  bookIcon,
  listRomanBigIcon,
  importIcon,
  menuIcon,
  parameterDateTimeIcon,
  folderMoreIcon,
  chevronDownIcon,
  chevronRightIcon,
  listOrderedIcon,
} from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const CustomItem = (props) => {
  const { visible, ...others } = props;
  const arrowDir = props.dataExpanded ? chevronDownIcon : chevronRightIcon;
  return props.visible === false ? null : (
    <DrawerItem {...others}>
      <SvgIcon icon={props.svgIcon} />
      <span
        className={"k-item-text"}
        style={{
          flex: "1 1 auto", // Allow the text to take up available space
          overflow: "hidden", // Prevent overflow
          textOverflow: "ellipsis", // Ellipsis for truncated text
          whiteSpace: "nowrap",
          fontSize: "14px", // Keep text in a single line
        }}
      >
        {props.text}
      </span>
      {props.dataExpanded !== undefined && (
        <SvgIcon
          icon={arrowDir}
          style={{
            marginLeft: "auto",
          }}
        />
      )}
    </DrawerItem>
  );
};

export const DrawerContainer = (props) => {
  const navigate = useNavigate();
  const [drawerExpanded, setDrawerExpanded] = React.useState(true);
  const [showDialog, setShowDialog] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const [items, setItems] = React.useState([
    {
      text: "Dashboard",
      svgIcon: homeIcon,
      id: 1,
      selected: true,
      route: "/home/dashboard",
    },
    {
      separator: true,
    },
    {
      text: "Booking management",
      svgIcon: bookIcon,
      id: 2,
      route: "/management/listbooking",
      dataExpanded: true,
    },
    {
      text: "List of Bookings",
      svgIcon: listOrderedIcon,
      id: 3,
      parentId: 2,
      route: "/management/listbooking",
      level: 1,
    },

    {
      text: "Masters",
      svgIcon: importIcon,
      id: 4,
      //route: "/masters/duration",
      dataExpanded: true,
    },
    // {
    //   text: "Duration",
    //   svgIcon: parameterDateTimeIcon,
    //   id: 5,
    //   parentId: 4,
    //   route: "/masters/duration",
    //   level: 1,
    // },
    // {
    //   text: "Packages",
    //   svgIcon: folderMoreIcon,
    //   id: 6,
    //   parentId: 4,
    //   route: "/masters/package",
    //   level: 1,
    // },
    // {
    //   text: "Room Master",
    //   svgIcon: listOrderedIcon,
    //   id: 7,
    //   parentId: 4,
    //   route: "/masters/room",
    //   level: 1,
    // },
    // {
    //   separator: true,
    // },
  ]);

  const SignOutDialog = ({ onConfirm, onCancel }) => (
    <Dialog
      title={"Confirm Sign Out"}
      onClose={onCancel}
      className="custom-dialog"
    >
      <p>Are you sure you want to sign out?</p>
      <DialogActionsBar>
        <button className="k-button" onClick={onCancel}>
          Cancel
        </button>
        <button className="k-button k-primary" onClick={onConfirm}>
          Sign Out
        </button>
      </DialogActionsBar>
    </Dialog>
  );

  // const handleSignOut = (e) => {
  //   e.preventDefault();
  //   setShowDialog(true);
  // };

  const handleConfirm = () => {
    // Proceed with sign out
    localStorage.removeItem("authToken");
    window.location.href = "/";
  };

  const handleCancel = () => {
    setShowDialog(false);
  };

  const handleClick = () => {
    setDrawerExpanded(!drawerExpanded);
  };

  const handleSignOut = (e) => {
    e.preventDefault();

    // Show confirmation dialog
    // const confirmed = window.confirm("Are you sure you want to sign out?");

    // if (confirmed) {
    // Proceed with sign out
    localStorage.removeItem("authToken");

    // window.location.href = "/";
    navigate("/", { replace: true });
    // }
  };

  const onSelect = (ev) => {
    const currentItem = ev.itemTarget.props;
    const isParent = currentItem.dataExpanded !== undefined;
    const nextExpanded = !currentItem.dataExpanded;
    const newData = items.map((item) => {
      const { selected, dataExpanded: currentExpanded, id, ...others } = item;
      const isCurrentItem = currentItem.id === id;
      return {
        selected: isCurrentItem,
        dataExpanded:
          isCurrentItem && isParent ? nextExpanded : currentExpanded,
        id,
        ...others,
      };
    });
    navigate(ev.itemTarget.props.route);
    setItems(newData);
  };

  const data = items.map((item) => {
    const { parentId, ...others } = item;
    if (parentId !== undefined) {
      const parentEl = items.find((parent) => parent.id === parentId);
      return {
        ...others,
        visible: parentEl && parentEl.dataExpanded,
      };
    }
    return item;
  });

  return (
    <div>
      <div
        className="custom-toolbar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Button svgIcon={menuIcon} fillMode="flat" onClick={handleClick} />
          <span className="title">workfromhaven</span>
        </div>
        <div>
          <Button
            className="user-button k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            // onClick={handleSignOut}
            onClick={toggleDialog}
          >
            Sign Out
          </Button>
          <div>
            {visible && (
              <Dialog title={"Please confirm"} onClose={toggleDialog}>
                <p style={{ margin: "25px", textAlign: "center" }}>
                  Are you sure you want to sign out?
                </p>
                <DialogActionsBar>
                  <Button type="button" onClick={toggleDialog}>
                    No
                  </Button>
                  <Button type="button" onClick={handleSignOut}>
                    Yes
                  </Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </div>

      <Drawer
        expanded={drawerExpanded}
        mode="push"
        width={250}
        items={data}
        item={CustomItem}
        onSelect={onSelect}
      >
        <DrawerContent>
          {props.children} <Outlet />
        </DrawerContent>
      </Drawer>

      {/* {showDialog && (
        <SignOutDialog onConfirm={handleConfirm} onCancel={handleCancel} />
      )} */}
    </div>
  );
};
