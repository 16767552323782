import React, { useState } from "react";
import {
  Input,
  InputPrefix,
  TextBox,
  TextArea,
} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { chevronDownIcon } from "@progress/kendo-svg-icons";
import { Button } from "@progress/kendo-react-buttons";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/Room.scss";
import "../css/Common.scss";
import {
  RadioButton,
  RadioButtonChangeEvent,
} from "@progress/kendo-react-inputs";
import Breadcrumbs from "../components/Breadcrumbs";

export const CreateRoom = () => {
  const navigate = useNavigate();
  const {
    state: { infoId },
  } = useLocation();
  const defaultBookingDuration = "Select Booking Duration";
  const defaultPackages = "Select Packages";
  // console.log("Room Type:", infoId);

  const [formData, setFormData] = useState({
    roomtype: infoId.roomtype === "" ? "" : infoId.roomtype,
    bookingDuration:
      infoId.bookingDuration === ""
        ? defaultBookingDuration
        : infoId.bookingDuration,
    packages: infoId.packages === "" ? defaultPackages : infoId.packages,
    seats: infoId.seats === "" ? "" : infoId.seats,
    deposit: infoId.deposit === "" ? "" : infoId.deposit,
    roomRent: infoId.roomRent === "" ? "" : infoId.roomRent,
    tax: infoId.tax === "" ? "" : infoId.tax,
    overBooking: false,
    percentageBooking:
      infoId.percentageBooking === "" ? "" : infoId.percentageBooking,
    description: infoId.description === "" ? "" : infoId.description,
  });
  const bookingDurationList = ["30", "45", "60", "90", "120"];
  const packagesList = ["Weekly", "Monthly", "Quarterly", "Yearly"];
  const [selectedValue, setSelectedValue] = React.useState("yes");

  const [newBookingDuration, setBookingDuration] = useState(
    defaultBookingDuration
  );
  const [newPackages, setNewPackages] = useState(defaultPackages);
  // const [isOverBookingAllowed, setIsOverBookingAllowed] = useState(false);

  const radioHandleChange = React.useCallback(
    (e: RadioButtonChangeEvent) => {
      // console.log("Checkbox Value->", e);
      // const checkedValue = e.value;
      // setIsOverBookingAllowed(checkedValue === "yes" ? true : false);
      setSelectedValue(e.value);
    },
    [setSelectedValue]
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const createRoom = (e: any) => {
    e.preventDefault();
    // console.log(formData);
  };

  const cancelRoom = () => {
    navigate("/masters/room");
  };

  const backScreen = () => {
    navigate("/masters/room");
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Room Master",
      route: "/masters/room",
    },
    {
      id: "3",
      text: "Create Room",
      route: "",
    },
  ];

  return (
    <div className="overall-view ">
      <div className="overall-view">
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <hr className="hr-border" />
      </div>
      <div className="common-margin">
        <form onSubmit={createRoom}>
          <div className="center-view">
            <div className="form-row">
              <div className="form-group">
                <label>Room Type</label>
                <Input
                  className="book-input-view book-height"
                  name="roomtype"
                  value={formData.roomtype}
                  placeholder="Enter Room Type"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Booking Duration</label>
                <DropDownList
                  svgIcon={chevronDownIcon}
                  className="book-dropdown"
                  data={bookingDurationList}
                  name="bookingDuration"
                  value={formData.bookingDuration}
                  defaultItem={defaultBookingDuration}
                  onChange={(e) => setBookingDuration(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Packages</label>
                <DropDownList
                  svgIcon={chevronDownIcon}
                  className="book-dropdown"
                  data={packagesList}
                  name="packages"
                  value={formData.packages}
                  defaultItem={defaultPackages}
                  onChange={(e) => setNewPackages(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>No. of Seats</label>
                <Input
                  className="book-input-view book-height"
                  name="seats"
                  value={formData.seats}
                  placeholder="Enter No. of Seats"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Deposit</label>
                <TextBox
                  value={formData.deposit}
                  onChange={handleChange}
                  name="deposit"
                  placeholder="Enter Deposit"
                  prefix={() => (
                    <>
                      <InputPrefix
                        style={{ paddingLeft: "20px", paddingRight: "20px" }}
                      >
                        ₹
                      </InputPrefix>
                    </>
                  )}
                  style={{ width: "80%", height: "42px" }}
                />
              </div>
              <div className="form-group">
                <label>Room Rent</label>
                <TextBox
                  name="roomRent"
                  value={formData.roomRent}
                  onChange={handleChange}
                  placeholder="Enter Room Rent"
                  prefix={() => (
                    <>
                      <InputPrefix
                        style={{ paddingLeft: "20px", paddingRight: "20px" }}
                      >
                        ₹
                      </InputPrefix>
                    </>
                  )}
                  style={{ width: "80%", height: "42px" }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>Tax</label>
                <TextBox
                  name="tax"
                  value={formData.tax}
                  onChange={handleChange}
                  placeholder="Enter Tax"
                  prefix={() => (
                    <>
                      <InputPrefix
                        style={{ paddingLeft: "20px", paddingRight: "20px" }}
                      >
                        ₹
                      </InputPrefix>
                    </>
                  )}
                  style={{ width: "80%", height: "42px" }}
                />
              </div>
              <div className="form-group">
                <label>is overbooking allowed</label>
                <div>
                  <RadioButton
                    className="commom-text-padding"
                    name="group1"
                    value="yes"
                    checked={selectedValue === "yes"}
                    label="Yes"
                    onChange={radioHandleChange}
                  />
                  <RadioButton
                    className="commom-text-padding"
                    name="group1"
                    value="no"
                    checked={selectedValue === "no"}
                    label="No"
                    onChange={radioHandleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>% of over booking allowed</label>
                <TextBox
                  name="percentageBooking"
                  value={formData.percentageBooking}
                  onChange={handleChange}
                  placeholder="Enter %"
                  prefix={() => (
                    <>
                      <InputPrefix
                        style={{ paddingLeft: "20px", paddingRight: "20px" }}
                      >
                        %
                      </InputPrefix>
                    </>
                  )}
                  style={{ width: "80%", height: "42px" }}
                />
              </div>
            </div>
            <div className="form-row-area">
              <div className="form-group-area">
                <label>Description about room</label>
                <TextArea
                  name="description"
                  value={formData.description}
                  rows={3}
                  placeholder="Enter Description"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="button-group">
              <Button type="submit" className="button-view">
                CREATE
              </Button>
              <Button
                type="submit"
                className="common-button-view"
                onClick={backScreen}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
