import * as React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import {
  JapaneseFood,
  ItalianFood,
  Travel,
  Europe,
  NorthAmerica,
} from "./Content.jsx";
import { SignIn } from "./pages/SignIn.jsx";
import { SignUp } from "./pages/SignUp.jsx";
import { Home } from "./pages/Home.jsx";
import { Dashboard } from "./pages/Dashboard.jsx";
import { Duration } from "./pages/masters/Duration";
import { Packages } from "./pages/masters/Packages";
import { RoomMaster } from "./pages/masters/RoomMaster";
import { CreateRoom } from "./pages/masters/CreateRoom";
import { BookingForm } from "./pages/management/EditInfo";
import { BookingManagement } from "./pages/management/BookingList";
import { DrawerContainer } from "./components/DrawerContainer.jsx";
import "./styles.css";

const App = () => {
  const location = useLocation();
  const isAuthRoute =
    location.pathname === "/" || location.pathname === "/signup";
  return (
    <>
      <Routes>
        {isAuthRoute ? (
          <>
            <Route path="/" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
          </>
        ) : (
          <Route
            path="*"
            element={
              <DrawerContainer>
                <Routes>
                  <Route path="/home" element={<Home />} />
                  <Route path="/home/dashboard" element={<Dashboard />} />
                  <Route path="/food/japanese" element={<JapaneseFood />} />
                  <Route path="/food/italian" element={<ItalianFood />} />
                  <Route path="/travel" element={<Travel />} />
                  <Route path="/travel/europe" element={<Europe />} />
                  <Route path="/travel/america" element={<NorthAmerica />} />
                  <Route path="/masters/duration" element={<Duration />} />
                  <Route path="/masters/package" element={<Packages />} />
                  <Route path="/masters/room" element={<RoomMaster />} />
                  <Route
                    path="/masters/room/createroom"
                    element={<CreateRoom />}
                  />
                  <Route
                    path="/management/editbooking"
                    element={<BookingForm />}
                  />
                  <Route
                    path="/management/listbooking"
                    element={<BookingManagement />}
                  />
                </Routes>
              </DrawerContainer>
            }
          />
        )}
      </Routes>
      {/* <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
      <DrawerContainer>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/home/dashboard" element={<Dashboard />} />
          <Route path="/food/japanese" element={<JapaneseFood />} />
          <Route path="/food/italian" element={<ItalianFood />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/travel/europe" element={<Europe />} />
          <Route path="/travel/america" element={<NorthAmerica />} />
          <Route path="/masters/duration" element={<Duration />} />
          <Route path="/masters/package" element={<Packages />} />
          <Route path="/masters/room" element={<RoomMaster />} />
          <Route path="/masters/room/createroom" element={<CreateRoom />} />
          <Route path="/management/editbooking" element={<BookingForm />} />
          <Route
            path="/management/listbooking"
            element={<BookingManagement />}
          />
        </Routes>
      </DrawerContainer> */}
    </>
  );
};
export default App;
