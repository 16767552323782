import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { process } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";

const inclusions = ["Inclusion 1", "Inclusion 2", "Inclusion 3"];
const exclusions = ["Exclusions 1", "Exclusions 2", "Exclusions 3"];
const defaultInclusionItem = "Choose Inclusion";
const defaultExclusionItem = "Choose Exclusion";

export const Packages = () => {
  const [packages, setPackages] = useState([
    {
      id: 1,
      package: "package1",
      inclusion: "Electricity Charges Dedicated desk us.",
      exclusion: "NA",
    },
    {
      id: 2,
      package: "package2",
      inclusion: "NA",
      exclusion: "Electricity Charges not included in this desk.",
    },
  ]);
  const [newPackage, setNewPackage] = useState("");
  const [newInclusion, setNewInclusion] = useState(defaultInclusionItem);
  const [newExclusion, setNewExclusion] = useState(defaultExclusionItem);
  const [editID, setEditID] = useState(null);
  const [deleteID, setDeleteID] = useState(null);
  const [dataState, setDataState] = useState({ skip: 0, take: 5 });

  const addPackage = () => {
    if (newPackage && (newInclusion || newExclusion)) {
      var notAvailableData = "";
      if (newInclusion === defaultInclusionItem) {
        notAvailableData = "NA";
      }
      if (newExclusion === defaultExclusionItem) {
        notAvailableData = "NA";
      }
      setPackages([
        ...packages,
        {
          id: packages.length + 1,
          package: newPackage,
          inclusion:
            newInclusion === defaultInclusionItem
              ? notAvailableData
              : newInclusion,
          exclusion:
            newExclusion === defaultExclusionItem
              ? notAvailableData
              : newExclusion,
        },
      ]);
      setNewPackage("");
      setNewInclusion(defaultInclusionItem);
      setNewExclusion(defaultExclusionItem);
    }
  };

  const clearInput = () => {
    setNewPackage("");
    setNewInclusion(defaultInclusionItem);
    setNewExclusion(defaultExclusionItem);
  };

  const updatePackage = (
    id: any,
    name: any,
    inclusion: any,
    exclusion: any
  ) => {
    setPackages(
      packages.map((p) =>
        p.id === id ? { ...p, name, inclusion, exclusion } : p
      )
    );
    setEditID(null);
  };

  const deletePackage = (id: any) => {
    setPackages(packages.filter((p) => p.id !== id));
    setDeleteID(null);
  };

  const handleDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = packages.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Packages",
      route: "",
    },
  ];

  return (
    <div className="overall-view ">
      <div className="overall-view">
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <hr className="hr-border" />
      </div>
      <div className="view-margin">
        <div className="common-margin">
          <div className="custom-input-container">
            <div className="custom-input-view">
              <Label className="common-label-size">Package Name</Label>
              <Input
                className="book-height"
                type="text"
                name="customername"
                value={newPackage}
                onChange={(e) => setNewPackage(e.value)}
                placeholder="Enter Package Name"
              />
            </div>
            <div className="custom-input-view">
              <Label className="common-label-size common-label-padding">
                Inclusion
              </Label>
              <DropDownList
                data={inclusions}
                value={newInclusion}
                defaultItem={defaultInclusionItem}
                name="roomtype"
                className="custom-dropdown"
                onChange={(e) => setNewInclusion(e.target.value)}
              />
            </div>
            <div className="custom-input-view common-label-padding">
              <Label className="common-label-size common-label-padding">
                Exclusion
              </Label>
              <DropDownList
                data={exclusions}
                value={newExclusion}
                defaultItem={defaultExclusionItem}
                className="custom-dropdown"
                onChange={(e) => setNewExclusion(e.target.value)}
              />
            </div>
            <div className="custom-input-view common-label-padding">
              <Button className="button-view " onClick={addPackage}>
                Create
              </Button>
              <Button className="common-button-view" onClick={clearInput}>
                Clear
              </Button>
            </div>
          </div>
        </div>

        <div className="common-margin view-margin">
          <Label className="list-heading">List</Label>
          <div>
            <Grid
              data={process(packages, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={packages.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handlePageChange}
            >
              <GridColumn field="id" title="S.No." width="75px" />
              <GridColumn
                field="package"
                title="Package Name"
                cell={(props) =>
                  editID === props.dataItem.id ? (
                    <td>
                      <input
                        type="text"
                        value={props.dataItem.package}
                        onChange={(e) =>
                          updatePackage(
                            props.dataItem.id,
                            e.target.value,
                            props.dataItem.inclusion,
                            props.dataItem.exclusion
                          )
                        }
                      />
                    </td>
                  ) : (
                    <td>{props.dataItem.package}</td>
                  )
                }
              />
              <GridColumn
                field="inclusion"
                title="Inclusion"
                cell={(props) =>
                  editID === props.dataItem.id ? (
                    <td>
                      <DropDownList
                        data={inclusions}
                        value={props.dataItem.inclusion}
                        onChange={(e) =>
                          updatePackage(
                            props.dataItem.id,
                            props.dataItem.package,
                            e.target.value,
                            props.dataItem.exclusion
                          )
                        }
                      />
                    </td>
                  ) : (
                    <td>{props.dataItem.inclusion}</td>
                  )
                }
              />
              <GridColumn
                field="exclusion"
                title="Exclusion"
                cell={(props) =>
                  editID === props.dataItem.id ? (
                    <td>
                      <DropDownList
                        data={exclusions}
                        value={props.dataItem.exclusion}
                        onChange={(e) =>
                          updatePackage(
                            props.dataItem.id,
                            props.dataItem.package,
                            props.dataItem.inclusion,
                            e.target.value
                          )
                        }
                      />
                    </td>
                  ) : (
                    <td>{props.dataItem.exclusion}</td>
                  )
                }
              />
              <GridColumn
                title="Action"
                className="header-center"
                width="300px"
                cell={(props) => (
                  <td>
                    <Button
                      className="edit-button-view"
                      onClick={() => setEditID(props.dataItem.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      className="danger-button-view"
                      onClick={() => setDeleteID(props.dataItem.id)}
                    >
                      Delete
                    </Button>
                  </td>
                )}
              />
            </Grid>
            {deleteID && (
              <Dialog title="Confirm" onClose={() => setDeleteID(null)}>
                <p>Are you sure you want to delete this row?</p>
                <DialogActionsBar>
                  <Button onClick={deletePackage}>Yes</Button>
                  <Button onClick={() => setDeleteID(null)}>No</Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
