import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { process } from "@progress/kendo-data-query";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import "../css/Duration.scss";
import "../css/Common.scss";

export const RoomMaster = () => {
  const navigate = useNavigate();

  const [roomData, setRoomData] = useState([
    {
      id: 1,
      roomtype: "Lounge",
      bookingDuration: "30",
      packages: "Weekly",
      seats: "2",
      deposit: "100",
      roomRent: "350",
      tax: "25",
      overBooking: false,
      percentageBooking: "5",
      description: "Welcome to all",
    },
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteID, setDeleteID] = useState(null);
  const [dataState, setDataState] = useState({ skip: 0, take: 5 });

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleCreate = () => {
    navigate("/masters/room/createroom", { state: { infoId: [] } });
  };

  const filteredData = roomData.filter((item) =>
    item.roomtype.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const deleteDuration = () => {
    setRoomData(roomData.filter((d) => d.id !== deleteID));
    setDeleteID(null);
  };

  const resetSearch = () => {
    setSearchTerm("");
  };

  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = roomData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const setEdit = (item: any) => {
    navigate("/masters/room/createroom", { state: { infoId: item } });
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Room Master",
      route: "",
    },
  ];

  return (
    <div className="overall-view ">
      <div className="overall-view">
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <hr className="hr-border" />
      </div>
      <div className="view-margin">
        <div className="common-margin">
          <div className="large-custom-input-container">
            <TextBox
              className="custom-input-view book-height"
              placeholder="Search by Room Type"
              name="roomcost"
              value={searchTerm}
              onChange={handleSearchChange}
              suffix={() => (
                <span className="input-icon">
                  {searchTerm ? (
                    <Button icon="close" onClick={resetSearch} />
                  ) : (
                    <Button icon="search" />
                  )}
                </span>
              )}
            />
            <Button className="large-button-view" onClick={handleCreate}>
              Create a new room
            </Button>
          </div>
        </div>
        <div className="common-margin view-margin">
          <Label className="list-heading">List</Label>
          <div>
            <Grid
              data={process(filteredData, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={filteredData.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handlePageChange}
            >
              <GridColumn field="id" title="S.No." width="75px" />
              <GridColumn field="roomtype" title="Room Type" />
              <GridColumn field="bookingDuration" title="Booking Duration" />
              <GridColumn field="packages" title="Package Details" />
              <GridColumn field="roomRent" title="Price" />
              <GridColumn
                title="Action"
                className="header-center"
                width="300px"
                cell={(props) => (
                  <td>
                    <Button
                      className="edit-button-view"
                      onClick={() => setEdit(props.dataItem)}
                    >
                      Edit
                    </Button>
                    <Button
                      className="danger-button-view"
                      onClick={() => setDeleteID(props.dataItem.id)}
                    >
                      Delete
                    </Button>
                  </td>
                )}
              />
            </Grid>
            {deleteID && (
              <Dialog
                className="dialog-view"
                title="Confirm"
                onClose={() => setDeleteID(null)}
              >
                <Label>Are you sure you want to delete this row?</Label>
                <DialogActionsBar>
                  <Button onClick={deleteDuration}>Yes</Button>
                  <Button onClick={() => setDeleteID(null)}>No</Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
