import React, { useState } from "react";
import {
  Grid,
  GridColumn,
  GridItemChangeEvent,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { process } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";

export const Duration = () => {
  const [durations, setDurations] = useState([
    { id: 1, type: "One Hour" },
    { id: 2, type: "Four Hours" },
  ]);
  const [newDuration, setNewDuration] = useState("");
  const [editID, setEditID] = useState(null);
  const [deleteID, setDeleteID] = useState(null);
  const [dataState, setDataState] = useState({ skip: 0, take: 5 });
  const pageSizes = [durations.length];
  var editEnable = false;

  // Add duration
  const addDuration = () => {
    if (newDuration) {
      setDurations([
        ...durations,
        { id: durations.length + 1, type: newDuration },
      ]);
    }
    setNewDuration("");
  };

  // Reset input fields
  const clearInput = () => {
    setNewDuration("");
  };

  //
  const updateDuration = (id: any, type: any) => {
    setDurations(durations.map((d) => (d.id === id ? { ...d, type } : d)));
    setEditID(null);
  };

  const editDuration = (id: any, type: any) => {
    setDurations(durations.map((d) => (d.id === id ? { ...d, type } : d)));
    setEditID(null);
  };

  // Delete duration particular row
  const deleteDuration = () => {
    setDurations(durations.filter((d) => d.id !== deleteID));
    setDeleteID(null);
  };

  // Pagination navigation
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  // Enable edit field
  const enableField = (id: any) => {
    editEnable = true;
    setEditID(id);
  };

  // Pagination item view
  const getPageableSettings = () => {
    const length = durations.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  // Edit flow
  const itemChange = (event: GridItemChangeEvent) => {
    const newData = durations.map((item) =>
      item.type === event.dataItem.ProductID
        ? {
            ...item,
            [event.field || ""]: event.value,
          }
        : item
    );
    setDurations(newData);
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Duration",
      route: "",
    },
  ];

  return (
    <div className="overall-view ">
      <div className="overall-view">
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <hr className="hr-border" />
      </div>
      <div className="view-margin">
        <div className="common-margin">
          <Label className="common-label-size">Duration Type</Label>
          <div className="custom-input-container">
            <Input
              className="custom-input-view  book-height"
              type="text"
              name="customername"
              value={newDuration}
              onChange={(e) => setNewDuration(e.value)}
              placeholder="Enter Duration Type"
            />
            <Button className="button-view " onClick={addDuration}>
              Create
            </Button>
            <Button className="common-button-view" onClick={clearInput}>
              Clear
            </Button>
          </div>
        </div>
        <div className="common-margin view-margin">
          <Label className="list-heading">List</Label>
          <div>
            <Grid
              data={process(durations, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={durations.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handlePageChange}
              onItemChange={itemChange}
            >
              <GridColumn field="id" title="S.No." />
              <GridColumn
                field="type"
                title="Duration Type"
                cell={(props) =>
                  editID === props.dataItem.id ? (
                    <td>
                      <input
                        type="text"
                        value={props.dataItem.type}
                        onChange={(e) =>
                          updateDuration(props.dataItem.id, e.target.value)
                        }
                      />
                    </td>
                  ) : (
                    <td>{props.dataItem.type}</td>
                  )
                }
              />
              <GridColumn
                title="Action"
                className="header-center"
                cell={(props) => (
                  <td>
                    {editEnable === false ? (
                      <Button
                        className="edit-button-view"
                        onClick={() => enableField(props.dataItem.id)}
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        className="edit-button-view"
                        onClick={() =>
                          editDuration(props.dataItem.id, props.dataItem.value)
                        }
                      >
                        Done
                      </Button>
                    )}
                    <Button
                      className="danger-button-view"
                      onClick={() => setDeleteID(props.dataItem.id)}
                    >
                      Delete
                    </Button>
                  </td>
                )}
              />
            </Grid>
            {deleteID && (
              <Dialog title="Confirm" onClose={() => setDeleteID(null)}>
                <p>Are you sure you want to delete this row?</p>
                <DialogActionsBar>
                  <Button onClick={deleteDuration}>Yes</Button>
                  <Button onClick={() => setDeleteID(null)}>No</Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
